import React from 'react'
import styles from '../assets/css/childStory.module.css'
import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { getMonth, checkBirthday } from '../Engines/DateEngine'

export default function ChildStory({
  child,
  closeChildStoryHandle,
  hideSponsor,
}) {
  return (
    <div className={styles.childStoryContainer}>
      <button onClick={closeChildStoryHandle} className={styles.closeStory}>
        +
      </button>
      <div className={styles.storyHeader}>
        <Row>
          <Col md="3">
            <div className={styles.storyImageWrapper}>
              <Card.Img
                variant="top"
                src={child.childPhoto}
                className={styles.childCardImage}
              />
            </div>
          </Col>
          <Col md="9">
            <div className={styles.aboutChild}>
              <h3>
                <span>{child.firstName}</span> {child.lastName}{' '}
                {child.otherName}
              </h3>
              <Row>
                <div className={styles.rightAboutChild}>
                  <p>
                    Location: <span>{child.Location}</span>
                  </p>
                  <p>
                    Birthday:{' '}
                    <span>
                      {checkBirthday(child.birthDay)
                        ? `${child.birthDay}`
                        : getMonth(child.birthDay)}
                    </span>
                  </p>
                  <p>
                    Education level: <span>{child.level}</span>
                  </p>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.storyBody}>
        <p>{child.story}</p>
        <div className={styles.hobbies}>
          <h5>Hobbies</h5>
          <ul>
            {child.hobbies.map((hobby, i) => (
              <li key={i}>{hobby}</li>
            ))}
          </ul>
        </div>
        <div className={styles.childId}>{child.childID}</div>
      </div>
      {!hideSponsor && (
        <div className={styles.sponsor}>
          {!child.sponsored ? (
            <Link
              className={styles.childCardButton}
              variant="primary"
              to={`/sponsorachild/${child._id}`}
            >
              Sponsor Now
            </Link>
          ) : (
            <button className={styles.childCardButton} disabled>
              Sponsored
            </button>
          )}
        </div>
      )}
    </div>
  )
}
