import { useState } from "react";

const useInput = () => {
  const [input, setInput] = useState("");

  const inputChange = (e) => {
    setInput(e.target.value);
  };
  const clearInput = () => {
    setInput("");
  };
  return [input, inputChange, clearInput];
};

export default useInput;
