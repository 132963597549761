export function calculateAge(birthday) {
  let dob = new Date(birthday)
  let month_diff = Date.now() - dob.getTime()
  let age_dt = new Date(month_diff)
  let year = age_dt.getUTCFullYear()
  let age = Math.abs(year - 1970)
  return age
}

export function getMonth(birthday) {
  const months = [
    'January',
    'February',
    'march',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const mn = months[Number(birthday.substr(5, 2)) - 1]
  return `${birthday.substr(8, 2)}-${mn}-${birthday.substr(0, 4)}`
}

export function reverseBirthday(birthday) {
  return birthday.replaceAll('-', '/')
}

export function unReverseBirthday(birthday) {
  return new Date(birthday)
}

export function checkBirthday(bd) {
  var regExp = /[a-zA-Z]/g

  if (regExp.test(bd)) {
    return true
  } else {
    return false
  }
}
