import React from 'react'
import Children from '../Children'
import headerImage from '../../assets/images/sponsorheader.jpg'
import education from '../../assets/images/icons/sponsor-school.png'
import meals from '../../assets/images/icons/sponsor-meals.png'
import medical from '../../assets/images/icons/sponsor-medication.png'
import uniform from '../../assets/images/icons/sponsor-uniform.png'
import sendgiftImage from '../../assets/images/sendgift.jpg'
import katrinaS from '../../assets/images/sponsor-katrina.jpg'
import katrinaSmini from '../../assets/images/katrina-mini.jpg'
import katieV from '../../assets/images/Katie-Vinson.jpg'
import katieVmini from '../../assets/images/katie-mini.jpg'
import GarrettFamily from '../../assets/images/Garrett-Family.jpg'
import Garrettmin from '../../assets/images/Garrett-mini.jpg'
import melaniP from '../../assets/images/sponsor-Melanie.jpg'
import skopeceksmini from '../../assets/images/Jennifer-mini.jpg'
import skopeceks from '../../assets/images/sponsor-Jennifer.jpg'
import melaniPmini from '../../assets/images/Melanie-mini.jpg'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Container, Row, Col } from 'react-bootstrap'
import styles from '../../assets/css/childrenPage.module.css'

const sponsors = [
  {
    name: 'Katie Vinson',
    imagemini: katieVmini,
    imagemain: katieV,
    text:
      ' Jeslor and the team at TFM have always been faithful stewards of the donations they recieve. I love to see the pictures they post of the children, and always look forward to getting their handmade cards and speaking with them on skype. It has been a blessing to me to get to know Jeslor, the volunteers, and all the children and I hope to be able to visit them one day!',
    since: 'Sponsor since 2017.',
  },
  {
    name: 'Katrina Steiner',
    imagemini: katrinaSmini,
    imagemain: katrinaS,
    text:
      'I am very grateful for the opportunity to support Trust Future Ministries and the safety, love, and hope they provide for the children they serve',
  },
  {
    name: 'The Potters',
    imagemini: melaniPmini,
    imagemain: melaniP,
    text:
      "I love sponsoring kids from Trust Future Ministries. I sponsor four in honor of my children and grandchildren. It's fun and inspirational to get letters and really connect with kids on the other side of the the world. We get to celebrate and pray with them and they teach my kids compassion and value. Best thing I can do each month.",
  },
  {
    name: 'The skopeceks',
    imagemini: skopeceksmini,
    imagemain: skopeceks,
    text:
      ' We are the Skopecek family from Indiana, US. We have been blessed with the opportunity to help support TFM & their mission to care for their community. We sponsor 3 children & love watching them grow! With TFM, you can be confident that your prayers & contributions are appreciated & being used to do God’s work!',
  },
  {
    name: 'Garrett Family',
    imagemini: Garrettmin,
    imagemain: GarrettFamily,
    text:
      ' The Garrett family chose to sponsor children at Trust Futures to give these bright, kind children the support they need to flourish.',
  },
]

export default function ChildrenPage({ setChildHandler }) {
  return (
    <div>
      <Container className={styles.childrenPageHeader}>
        <Row>
          <Col lg="4">
            <div className={styles.headerImageWrapper}>
              <img src={headerImage} alt="children header" />
            </div>
          </Col>
          <Col lg="8">
            <div className={styles.headerText}>
              <p>
                Child sponsorship is one way one can bring hope to an orphan or
                vulnerable child at Trust Future Ministries. Many children are
                waiting for sponsors, and they cannot go to school without your
                help! Most importantly, sponsorship shows these children that
                someone else out there loves them and cares about their future.
              </p>
              <div className={styles.sponsorshipProvides}>
                <h4>Your sponsorship provides the following for the child:</h4>
                <ul>
                  <li>
                    <div className={styles.spIcons}>
                      <img src={education} alt="school icon" />
                    </div>
                    A Christian Education
                  </li>
                  <li>
                    <div className={styles.spIcons}>
                      <img src={uniform} alt="school icon" />
                    </div>
                    School uniform, books and other supplies
                  </li>
                  <li>
                    <div className={styles.spIcons}>
                      <img src={medical} alt="school icon" />
                    </div>
                    Regular medical check-ups and a basic health care
                  </li>
                  <li>
                    <div className={styles.spIcons}>
                      <img src={meals} alt="school icon" />
                    </div>
                    Daily hot nutritious meals
                  </li>
                </ul>
              </div>

              {/* <p>
                Check out the stories of some of our kids and young adults
                waiting to be sponsored. Sign up today and make a lasting impact
                in their lives. The cost per month depends on the educational
                level of the child, not their age.
              </p>
              <ul>
                <li>PRIMARY LEVEL- $38 USD/month</li>
                <li>SECONDARY LEVEL- $42 USD/month</li>
                <li>POST SECONDARY TRAINING/EDUCATION- $50 USD/month</li>
              </ul>
              <p>
                Thank you for being the help to the children in need at AMF!
              </p> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div class={styles.testmonials}>
              <div class="row container-header">
                <Col lg="8" className="mx-auto text-center">
                  <h2>Sponsor Testimonials</h2>
                </Col>
              </div>

              <div className={styles.sponsorCarousel}>
                <div class="container-fluid">
                  <div className="row title" style={{ marginBottom: '20px' }}>
                    {' '}
                  </div>
                </div>
                <div class="container-fluid">
                  <OwlCarousel
                    items={1}
                    className="owl-theme"
                    loop
                    nav
                    margin={8}
                    autoplay={true}
                    autoplayTimeout={8500}
                    smartSpeed={450}
                  >
                    {sponsors.map((sponsor, index) => (
                      <div key={index}>
                        <Row>
                          <Col lg="6">
                            <div className={styles.tesmonialImage}>
                              <img
                                src={sponsor.imagemain}
                                alt="Sponsor tesmonial 1"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className={styles.testimonialWrapper}>
                              <div className={styles.testimonial}>
                                <div className={styles.testmonialContent}>
                                  {sponsor.text}
                                  <br />
                                  <strong className={styles.spaceSince}>
                                    <em>{sponsor.since}</em>
                                  </strong>
                                </div>
                              </div>
                              <div className={styles.minSponsor}>
                                <img
                                  src={sponsor.imagemini}
                                  alt="Sponsor tesmonial 1"
                                />
                                <h6>{sponsor.name}</h6>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.sendgiftContatiner}>
        <Container>
          <Row>
            <Col md="6">
              <div className={styles.sendGift}>
                <h4>Sending a special gift to a needy child at TFM.</h4>
                <p className="py-2">
                  As a child sponsor, you can send a special gift to your child
                  at Trust Future ministries These gifts are meaningful to a
                  child because most children here at Trust Future Ministries
                  have never received a gift of any kind.
                </p>
                <p className="py-2">
                  Gifts may include school apparatuses, clothes, shoes, toys,
                  drinking bottle, etc. A sponsor can also consider writing a
                  letter to his or her child at Trust Futue Ministries using our
                  guideline found in the package that is sent to you after
                  choosing a child.
                </p>
              </div>
            </Col>
            <Col md="6">
              <div className={styles.sendGift}>
                <div className={styles.imageWrapper}>
                  <img
                    className="img-fluid"
                    src={sendgiftImage}
                    alt="sendgift presentation"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg="8 mx-auto">
            <div className={styles.becomeSponsor}>
              <h2>Become a Child sponsor</h2>
              <p>
                This is going to be a three steps process. You will first of all
                choose a child or children you would like to sponsor from the
                list below, on the next step you will fill the sponsor form with
                your personal, company or organisation information, submit the
                form and then activate a monthly sponsorship.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.chooseChild}>
              <p>
                Check out the stories of some of our kids and young adults
                waiting to be sponsored. Sign up today and make a lasting impact
                in their lives. The cost per month depends on the educational
                level of the child, not their age.
              </p>
              <ul>
                <li>PRIMARY LEVEL - $32 USD/month</li>
                <li>SECONDARY LEVEL - $52 USD/month</li>
                <li>
                  TERTIARY LEVEL (TRAINING/UNIVERSITY or VOCATIONAL EDUCATION) -
                  $75 USD/month
                </li>
              </ul>
              <p>Thank you for joining with us to bring help and hope!</p>
            </div>
          </Col>
        </Row>
      </Container>

      <Children setChildHandler={setChildHandler} />
    </div>
  )
}
