import React, { useState, useRef, useEffect } from "react";
import styles from "../assets/css/hobbies.module.css";
import useInput from "../Hooks/useInputHook";
import { v4 as hobbyId } from "uuid";

export default function HobbiesComponent({ pullInHobbies, savedHobbies }) {
  let initialHobbies = savedHobbies.length ? savedHobbies : [];
  const [hobbies, setHobbies] = useState(initialHobbies);
  const [hobby, setHobby, clearHobby] = useInput();
  const addHobbyRef = useRef();

  useEffect(() => {
    pullInHobbies(hobbies);
  }, [hobbies]);

  const handleAddHobby = (e) => {
    e.preventDefault();
    setHobbies([...hobbies, { hobby, id: hobbyId() }]);
    clearHobby();
    addHobbyRef.current.focus();
  };

  const handleRemoveHobbie = (e, id) => {
    e.preventDefault();
    setHobbies(hobbies.filter((h) => h.id !== id));
  };

  return (
    <div>
      <div className={styles.enteredHobbies}>
        {hobbies.map((h) => (
          <div className={styles.eachHobby} key={h.id}>
            {h.hobby}
            <button onClick={(e) => handleRemoveHobbie(e, h.id)}>+</button>
          </div>
        ))}
      </div>

      <div className={styles.addingHobby}>
        <input
          ref={addHobbyRef}
          type="text"
          value={hobby}
          onChange={setHobby}
        />
        <button onClick={handleAddHobby} className={styles.addhobby}>
          Add hobby
        </button>
      </div>
    </div>
  );
}
