import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword } from "../../Engines/Firebase";
import { Row, Col } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import styles from "../../assets/css/login.module.css";

export default function LoginPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };

  useEffect(() => {
    if (loading) {
      console.log("currently loading");
      return;
    }
    if (user) {
      navigate("/");
    }
    if (error) {
      console.log("failed to login", error);
    }
  }, [user, loading]);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.innerContent}>
        <h4> Register new Admin</h4>
        <Row>
          <Col className={styles.formInputs}>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter fullname"
            />
          </Col>
        </Row>

        <Row>
          <Col className={styles.formInputs}>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
          </Col>
        </Row>

        <Row>
          <Col className={styles.formInputs}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </Col>
        </Row>
        <Row>
          <Col className={styles.formInputs}>
            <button className={styles.loginButton} onClick={register}>
              Register
            </button>
          </Col>
        </Row>
        <Row>
          <Col className={styles.formInputs}>
            <div>
              Already have an account?{" "}
              <Link className={styles.connectLink} to="/login">
                Login
              </Link>{" "}
              now.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
