import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../Engines/Firebase";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import HobbiesComponent from "../HobbiesComponent";
import styles from "../../assets/css/addChild.module.css";

const educationLevels = [
  { name: "select level", selected: true },
  { name: "primary" },
  { name: "secondary" },
  { name: "institute" },
];

export default function EditChildPage() {
  const { editChildId } = useParams();
  const navigate = useNavigate();
  const [hobbies, setHobbies] = useState([]);
  const [childFirstName, setChildFirstName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [childOtherName, setChildOtherName] = useState("");
  const [childStory, setChildStory] = useState("");
  const [childID, setChildID] = useState("");
  const [childLocation, setChildLocation] = useState("");
  const [childEducationLevel, setChildEducationLevel] = useState("");
  const [childBirthday, setChildBirthday] = useState("");

  const pullInHobbies = (hobs) => {
    setHobbies(hobs);
  };

  useEffect(() => {
    const getChild = async () => {
      try {
        const docRef = doc(db, "children", editChildId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const foundChildData = docSnap.data();
          setChildFirstName(foundChildData.firstName);
          setChildLastName(foundChildData.lastName);
          setChildOtherName(foundChildData.otherName);
          setChildLocation(foundChildData.Location);
          setChildEducationLevel(foundChildData.level);
          setChildBirthday(foundChildData.birthDay);
          setChildID(foundChildData.childID);
          setChildStory(foundChildData.story);
          setHobbies(foundChildData.hobbies);
        }
      } catch (error) {
        console.log("we accounted this error", error);
      }
    };
    getChild();
  }, [editChildId]);

  const updateChildHandler = (e) => {
    e.preventDefault();
    pullInHobbies();
    const updatedChild = {
      level: childEducationLevel,
      childID: childID,
      firstName: childFirstName,
      lastName: childLastName,
      otherName: childOtherName,
      birthDay: childBirthday,
      Location: childLocation,
      story: childStory,
    };

    const updateChild = async () => {
      const docRef = doc(db, "children", editChildId);
      try {
        await updateDoc(docRef, {
          ...updatedChild,
        });
        await updateDoc(docRef, {
          hobbies: [...hobbies],
        });

        navigate("/");
      } catch (error) {
        console.log("error in updating in the document", error);
      }
    };

    updateChild();
  };

  return (
    <div className={styles.addachildPage}>
      <div className={styles.article}>
        <form onSubmit={updateChildHandler}>
          <div className={styles.childInformation}>
            <h4 className={styles.sectionHeader}>Child Information</h4>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>First Name*</label>
                <input
                  type="text"
                  required
                  minLength="3"
                  defaultValue={childFirstName}
                  onChange={(e) => setChildFirstName(e.target.value)}
                />
              </Col>
              <Col className={styles.formInputs} lg="6">
                <label>Last Name</label>
                <input
                  type="text"
                  minLength="3"
                  value={childLastName}
                  onChange={(e) => setChildLastName(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>Other Name</label>
                <input
                  type="text"
                  minLength="3"
                  value={childOtherName}
                  onChange={(e) => setChildOtherName(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>Child ID</label>
                <input
                  type="text"
                  required
                  value={childID}
                  onChange={(e) => setChildID(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label>BirthDay</label>
                <input
                  type="text"
                  required
                  value={childBirthday}
                  onChange={(e) => setChildBirthday(e.target.value)}
                  placeholder="Enter date like 2009/02/23"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label htmlFor="education">Education Level*</label>
                <select
                  value={childEducationLevel}
                  onChange={(e) => setChildEducationLevel(e.target.value)}
                  id="education"
                  required
                >
                  {educationLevels.map((option) => (
                    <option
                      key={option.name}
                      value={option.name}
                      defaultValue={option.selected}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs} lg="6">
                <label> Child Location</label>
                <input
                  type="text"
                  placeholder="Kampala, Uganda"
                  value={childLocation}
                  onChange={(e) => setChildLocation(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="py-3">
              <Col className={styles.formInputs}>
                <label>Child Story</label>
                <textarea
                  rows="7"
                  value={childStory}
                  required
                  onChange={(e) => setChildStory(e.target.value)}
                  placeholder="You must enter a child's story"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className={styles.formInputs}>
                <label>Hobbies</label>
                {hobbies && hobbies.length && (
                  <HobbiesComponent
                    savedHobbies={hobbies}
                    pullInHobbies={pullInHobbies}
                  />
                )}
              </Col>
            </Row>
          </div>

          <button className={styles.addChildButton}>Update this Child</button>
        </form>
      </div>
    </div>
  );
}
