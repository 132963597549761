import React from 'react'
import { useParams } from 'react-router-dom'

export default function TestComponent() {
  const { testme } = useParams()

  return (
    <h1>
      {' '}
      Youare testing <b style={{ color: 'purple' }}>{testme}</b>{' '}
    </h1>
  )
}
