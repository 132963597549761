import React from "react";
import styles from "../assets/css/removeachild.module.css";
import { db } from "../Engines/Firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import cloudinary from "cloudinary/lib/cloudinary";

cloudinary.config({
  cloud_name: process.env.REACT_APP_CLOUD_NAME,
  api_key: process.env.REACT_APP_API_KEY,
  api_secret: process.env.REACT_APP_API_SECRET,
});

export default function RemoveaChild({
  childtodelte,
  handleCancelDeleteaChild,
}) {
  const Navigate = useNavigate();
  const handleCancelRemoveaChild = () => {
    handleCancelDeleteaChild(false);
  };
  const handleRemoveaChild = async () => {
    console.log("heare in reamove a child", childtodelte);
    try {
      cloudinary.v2.uploader.destroy(childtodelte.childPhotoId, function (
        error,
        result
      ) {
        return error;
      });
      const docRef = doc(db, "children", childtodelte.id);
      await deleteDoc(docRef);
      Navigate("/");
      handleCancelDeleteaChild(false);
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };
  return (
    <div className={styles.removeContainer}>
      <div className={styles.buttons}>
        <button
          className={styles.cancelDeleteButton}
          onClick={handleCancelRemoveaChild}
        >
          Cancel
        </button>
        <button
          className={styles.deleteAChildButton}
          onClick={handleRemoveaChild}
        >
          Remove Child
        </button>
      </div>
    </div>
  );
}
