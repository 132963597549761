import React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { FaRegHeart } from 'react-icons/fa'
import tfuLogo from '../assets/images/logo.png'

import styles from '../assets/css/navbar.module.css'

function NavScrollExample() {
  const scrollabeNav = [
    // "me - auto",
    'ms - auto',
    'my - 2',
    'my - lg - 0]',
    styles.scrollableLinks,
  ]
  return (
    <Navbar id="fixedNav" expand="lg" className={styles.navbarMain}>
      <div className={styles.sizeController}>
        <Navbar.Brand href="https://www.trustfutureministries.org/">
          <img src={tfuLogo} alt="ffl logo" className={styles.brandLogo} />
        </Navbar.Brand>
        <Navbar.Toggle
          id="firstButton"
          aria-controls="navbarScroll"
          className={styles.myToggler}
        >
          <div id="animatedIcon1" className={styles.animatedIcon1}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Navbar.Toggle>

        <Navbar.Collapse id="navbarScroll">
          <Nav className={styles.scrollabeNav} navbarScroll>
            <Nav.Link
              className={styles.navbarLinks}
              href="https://www.trustfutureministries.org/"
            >
              Home
            </Nav.Link>

            <NavDropdown
              className={styles.navbarLinks}
              title="About us"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="https://www.trustfutureministries.org/who-we-are.html">
                Who we are
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.trustfutureministries.org/our-team.html">
                Our team
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.trustfutureministries.org/statement-of-faith.html">
                What we Believe
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className={styles.navbarLinks}
              title="Our work"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="https://www.trustfutureministries.org/education.html">
                Education
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.trustfutureministries.org/empowerment.html">
                Empowerment
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.trustfutureministries.org/orphan-care.html">
                Orphan care
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.trustfutureministries.org/sustainability.html">
                Sustainability
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              className={styles.navbarLinks}
              title="Join us"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/">Sponsor a Child</NavDropdown.Item>
              <NavDropdown.Item href="https://www.trustfutureministries.org/volunteer.html">
                Volunteering
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.trustfutureministries.org/visit.html">
                Visit
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.trustfutureministries.org/pray.html">
                Pray with us
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              className={styles.navbarLinks}
              href="https://www.trustfutureministries.org/contact-us.html"
            >
              Contact us
            </Nav.Link>
          </Nav>
          <div className={styles.navButtons}>
            <a
              className={styles.donate}
              href="https://www.trustfutureministries.org/donate.html"
              variant="outline-success"
            >
              <FaRegHeart className={styles.buttonIcon} />
              Donate
            </a>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default NavScrollExample
