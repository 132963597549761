import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import { auth, logInWithEmailAndPassword } from '../../Engines/Firebase'
import { Row, Col } from 'react-bootstrap'
import { useAuthState } from 'react-firebase-hooks/auth'
import styles from '../../assets/css/login.module.css'
import axios from 'axios'

export default function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const logInWithEmailAndPassword = async () => {
    const res = await axios.post(
      'http://localhost:3000/sponsorachildplatform/loginuser',
      {
        email: email,
        password: password,
      },
    )
    console.log(res)
  }
  // const [user, loading, error] = useAuthState(auth);

  // useEffect(() => {
  //   if (loading) {
  //     console.log("currently loading");
  //     return;
  //   }
  //   if (user) {
  //     navigate("/");
  //   }
  //   if (error) {
  //     console.log("failed to login", error);
  //   }
  // }, [user, loading]);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.simpleLinks}>
        <Link className={styles.allChildren} to="/">
          All Children
        </Link>
      </div>
      <div className={styles.innerContent}>
        <h4> Login</h4>
        <Row>
          <Col className={styles.formInputs}>
            <input
              type="text"
              className="login__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
          </Col>
        </Row>

        <Row>
          <Col className={styles.formInputs}>
            <input
              type="password"
              className="login__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </Col>
        </Row>
        <Row>
          <Col className={styles.formInputs}>
            <button
              className={styles.loginButton}
              onClick={() => logInWithEmailAndPassword(email, password)}
            >
              Login
            </button>
          </Col>
        </Row>
        <Row>
          <Col className={styles.formInputs}>
            <div>
              <Link className={styles.connectLink} to="/reset">
                Forgot Password
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
