import React,{ useState, useEffect, memo} from 'react'
import styles from '../../assets/css/thankyouPage.module.css'
import { IoCheckmark } from "react-icons/io5";
import axios from 'axios'

const findSavedSponsor = localStorage.getItem('sponsorData');
const sponsorDataParsed = JSON.parse(findSavedSponsor);

const ThankyouPage = memo(() => {
    const [isLoading, setIsLoading] = useState(true)
    const [childToSponsor, setChildToSponsor] = useState(null)



    
    const getChildtoSponsor = async()=>{
        if (findSavedSponsor !== null) {
            let children = await axios.get(
            'https://www.sponsorachildplatform.jeslor.com/sponsorachildplatform/trust future ministries',
            )
            children = children.data[0].children
            setChildToSponsor(...children.filter((child) => child._id === sponsorDataParsed.childId))
        }
     }
        

    const saveSponsorInformation = async()=>{

        if (findSavedSponsor === null) {
            setTimeout(() => {
                window.location.href = 'https://www.trustfutureministries.org/';
            }, 10000);
            return;
        }

    }


    const completeSponsorship = async()=>{
        setIsLoading(true)
        try{
              await fetch(
                'https://www.sponsorachildplatform.jeslor.com/sponsorachildplatform/accounts/trust future ministries/sponsors',
                {
                  body: JSON.stringify(sponsorDataParsed),
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                },
              ).then(async (res) => {
                const data = await res.json()
                if (data.success === true) {
                    setIsLoading(false)
                    localStorage.removeItem('sponsorData');
                    setTimeout(() => {
                        window.location.href = 'https://www.sponsorachild.trustfutureministries.org';
                    }, 10000);
                }
            })
            
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        saveSponsorInformation()
        getChildtoSponsor()
    }, []);



  return (
        <div className={styles.thankyou}>
            {isLoading
            ?
            <div className={styles.loading}>
                <div className={styles.loader}></div>
            </div>
            :
            <div className={styles.complete}>
                <IoCheckmark />
            </div>
            }

        <h1>{sponsorDataParsed ===null?"Donation Completed!":`Last step ☺️`}</h1>

        {sponsorDataParsed ===null?(
        <>
            <div className={styles.thankyouMessage}>
            <p>
            Your generous support allows Trust Future Ministries to meet the basic, physical, and spiritual needs of many of the most vulnerable and orphan children, youths and widows in our community. Hope has been restored, lives saved, families re-united and communities changed because of your generosity.
            </p>
            </div>
            <div className={styles.links}>
                <a className={styles.thankyouLinks} href='https://www.trustfutureministries.org/'>
                    main page
                </a>
                <a className={styles.thankyouLinks} href='https://www.trustfutureministries.org/contact-us.html'>
                    contact page
                </a>
            </div>
        </>
        ):(
            <>
                <div className={styles.childImage}>
                    <img src={childToSponsor!==null && childToSponsor.childPhoto} alt="child" />
                </div>
                <div className={styles.thankyouMessage}>
                    <p>
                    Please complete the process of sponsoring <strong>{childToSponsor!==null &&`${childToSponsor.firstName} ${childToSponsor.lastName}`}</strong> by clicking on the button below
                    </p>
                </div>
                <button onClick={completeSponsorship} className={styles.thankyouLinks}>
                        Complete Sponsorship
                </button>
            </>
         )}
    </div>
    )
        
})


export default ThankyouPage